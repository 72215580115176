<template>
  <div class="turn-dialog">
    <a-modal
      title="轮巡设置"
      width="32%"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :centered="true"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template>
        <div class="flex items-center m-b-sm">
          <span class="wd-name">方案名称：</span>
          <a-input
            v-model="tempName"
            class="flex-one"
            placeholder="请输入方案名称"
          />
        </div>
        <div
          class="flex items-center m-b-sm"
          v-show="selectAllMode === 'all'"
          v-if="false"
        >
          <span class="wd-name">轮巡内容：</span>
          <a-select
            v-model="turnMode"
            placeholder="请选择轮巡内容"
            class="flex-one"
            @change="handleTurnModeChange"
          >
            <a-select-option
              v-for="item in turnModeList"
              :value="item.value"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <template v-if="false">
          <div
            class="flex items-center m-b-sm"
            v-if="turnMode === 0"
          >
            <span class="wd-name">选择内容：</span>
            <span
              class="text-lightblue flex items-center flex-one"
              ><!-- <i class="r-add m-r-sm" @click="addHandle"></i> --><a-icon
                type="plus-circle"
                class="m-r-sm"
                @click="addHandle"
              />已选
              <span class="text-line m-l-xs m-r-xs">{{
                selectCameraList.length
              }}</span>
              路摄像机</span
            >
          </div>
          <div
            class="flex items-center m-b-sm"
            v-if="turnMode === 1"
          >
            <span class="wd-name">选择内容：</span>
            <span
              class="text-lightblue flex items-center flex-one"
              ><!-- <i class="r-add m-r-sm" @click="addHandle"></i> --><a-icon
                type="plus-circle"
                class="m-r-sm"
                @click="addHandle"
              />已选
              <span class="text-line m-l-xs m-r-xs">{{
                selectCameraList.length
              }}</span>
              路摄像机</span
            >
          </div>
          <template v-if="turnMode === 2">
            <div class="flex items-center m-b-sm">
              <span class="wd-name">轮巡方式：</span>
              <a-select
                v-model="turnWay"
                placeholder="请选择轮巡方式"
                class="m-r-sm flex-one"
                @change="handleTurnWayChange"
              >
                <a-select-option
                  v-for="item in turnWayList"
                  :value="item.value"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div
              class="flex items-center m-b-sm"
              v-if="turnWay === 0 || turnWay === 1"
            >
              <span class="wd-name">轮巡内容：</span>
              <a-select
                v-model="turnContent"
                :mode="turnWay === 0 ? '' : 'multiple'"
                placeholder="请选择轮巡内容"
                class="flex-one"
                @change="handleTurnContentChange"
              >
                <a-select-option
                  v-for="item in cameraGroupLists"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.cameraGroupName }}
                </a-select-option>
              </a-select>
            </div>
          </template>
        </template>
        <template v-if="false">
          <div
            class="flex items-center m-b-sm"
            v-if="selectAllMode === 'collect'"
          >
            <span class="wd-name">轮巡内容：</span>
            <span
              class="text-lightblue flex items-center flex-one"
              ><!-- <i class="r-add m-r-sm" @click="addHandle"></i> --><a-icon
                type="plus-circle"
                class="m-r-sm"
                @click="addHandle"
              />已选
              <span class="text-line m-l-xs m-r-xs">{{
                selectCameraList.length
              }}</span>
              路摄像机</span
            >
          </div>
          <template v-if="selectAllMode === 'group'">
            <div class="flex items-center m-b-sm">
              <span class="wd-name">轮巡方式：</span>
              <a-select
                v-model="turnWay"
                placeholder="请选择轮巡方式"
                class="flex-one"
                @change="handleTurnWayChange"
              >
                <a-select-option
                  v-for="item in turnWayList"
                  :value="item.value"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div
              class="flex items-center m-b-sm"
              v-if="turnWay === 0 || turnWay === 1"
            >
              <span class="wd-name">轮巡内容：</span>
              <a-select
                v-model="turnContent"
                :mode="turnWay === 0 ? '' : 'multiple'"
                placeholder="请选择轮巡内容"
                class="flex-one"
                @change="handleTurnContentChange"
              >
                <a-select-option
                  v-for="item in cameraGroupLists"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.cameraGroupName }}
                </a-select-option>
              </a-select>
            </div>
          </template>
        </template>
        <div class="flex items-center m-b-sm">
          <span class="wd-name">预览模式：</span>
          <a-select
            v-model="screenSizeValue"
            placeholder="请选择画面"
            class="m-r-sm flex-one"
            @change="handleScreenSize"
          >
            <a-select-option
              v-for="item in screenSize"
              :value="item.value"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-select
            v-model="screenChildValue"
            class="flex-one"
            placeholder="请选择画面模式"
          >
            <a-select-option
              v-for="item in screenChild"
              :value="item.value"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="wd-name">切换频率：</span>
          <a-select
            v-model="switchPre"
            class="flex-one"
            placeholder="请选择切换频率"
          >
            <a-select-option
              v-for="item in switchArr"
              :value="item.value"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="wd-name">轮巡时间：</span>
          <div class="flex-one">
            <a-radio-group
              v-model="turnTime"
              @change="onChangeTurnTime"
            >
              <a-radio :value="1">
                立刻执行
              </a-radio>
              <a-radio :value="0">
                定时执行
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="m-b-sm" v-show="turnTime === 0">
          <div class="flex items-center">
            <span class="wd-name">执行时间：</span>
            <!-- <a-range-picker
            v-model="turnDateRange"
            format="YYYY-MM-DD"
            class="flex-one"
            showTime
            @change="changeDataRange"
          /> -->
            <a-date-picker
              v-model="turnDateNew"
              class="m-r-xs"
              @change="onChangeTurnDate"
              style="width:38%"
            />
            <a-select
              v-model="turnDateNewHour"
              @change="handleHourChange"
              class="m-r-xs"
              style="width:17%;"
            >
              <a-select-option
                v-for="item in hourArr"
                :key="item.value"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
            <span class="m-r-xs">时</span>
            <a-select
              v-model="turnDateNewMinute"
              @change="handleMinuteChange"
              style="width:17%;"
            >
              <a-select-option
                v-for="item in minuteArr"
                :key="item.value"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
            <span class="m-l-xs">分</span>
          </div>
          <div class="tips">
            本次轮巡将在
            {{
              `${getFormatDay(this.turnDateNew)}  
               ${turnDateNewHour}  
                :   
               ${turnDateNewMinute} `
            }}
            自动执行
          </div>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="wd-name">选择内容：</span>
          <span
            class="text-lightblue flex items-center flex-one"
            ><a-icon
              type="plus-circle"
              class="m-r-sm"
              @click="addHandle"
            />已选
            <span class="text-line m-l-xs m-r-xs">{{
              selectCameraList.length
            }}</span>
            路摄像机</span
          >
        </div>
        <div v-if="false" class="flex items-center m-b-sm">
          <span class="wd-name">清晰度：</span>
          <div class="flex-one">
            <a-radio-group
              v-model="clearType"
              @change="onChangeClearType"
            >
              <a-radio :value="0">
                标清
              </a-radio>
              <a-radio :value="1">
                高清
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="flex items-center">
          <span class="wd-name">轮巡描述：</span>
          <div class="flex-one">
            <a-textarea
              placeholder="请输入轮巡描述"
              v-model="turnRemark"
              :rows="4"
              :maxLength="200"
            />
          </div>
        </div>
        <span class="tips text-right text-red block"
          >200个字</span
        >
      </template>
    </a-modal>
    <cameral-select
      :visible="cameraSelectVisible"
      :cameraTabs="selectAllMode"
      @closeCameralDialog="closeCameralDialogHandle"
    />
  </div>
</template>

<script>
import CameralSelect from '@/components/CameralSelect'
import { mapState, mapActions, mapMutations } from 'vuex'
// import { timeDiff } from '@/tools';
import moment from 'moment'
const padLen = (n, len) => {
  return String(n).padStart(len, '0')
}
const getTimeArr = (len) => {
  let arr = []
  arr = new Array(len).fill(0)
  arr = arr.map((item, index) => {
    return {
      value: padLen(index + 1, 2),
      text: padLen(index + 1, 2)
    }
  })
  return arr
}

const SCREENSIZE = [
  {
    id: 0,
    value: 1,
    name: '单画面',
    children: [
      {
        id: 0,
        value: '1*1',
        name: '1*1'
      }
    ]
  },
  {
    id: 1,
    value: 4,
    name: '4画面',
    children: [
      {
        id: 0,
        value: '2*2',
        name: '2*2'
      }
    ]
  },
  {
    id: 2,
    value: 6,
    name: '6画面',
    children: [
      {
        id: 0,
        value: '2*3',
        name: '2*3'
      },
      {
        id: 1,
        value: '3*2',
        name: '3*2'
      }
    ]
  },
  {
    id: 3,
    value: 9,
    name: '9画面',
    children: [
      {
        id: 0,
        value: '3*3',
        name: '3*3'
      }
    ]
  },
  {
    id: 4,
    value: 12,
    name: '12画面',
    children: [
      {
        id: 0,
        value: '3*4',
        name: '3*4'
      },
      {
        id: 1,
        value: '4*3',
        name: '4*3'
      }
    ]
  },
  /* {
          id: 5,
          value: 15,
          name: '15画面',
          children: [
            {
              id: 0,
              value: '3*5',
              name: '3*5',
            },
            {
              id: 1,
              value: '5*3',
              name: '5*3',
            },
          ],
        }, */
  {
    id: 6,
    value: 16,
    name: '16画面',
    children: [
      {
        id: 0,
        value: '4*4',
        name: '4*4'
      }
    ]
  }
]

export default {
  name: 'TurnDialog',
  props: {
    visible: {
      type: Boolean
    },
    selectAllMode: {
      type: String,
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formItemLayout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      },
      confirmLoading: false,
      tempName: '新建轮巡预案',
      /*       screenMode: 'a',
      turnTime: 'a', */
      cameraSelectVisible: false,
      screenSizeValue: 4,
      turnTime: 1,
      turnDateNew: moment(),
      turnDateNewHour: moment().format('HH'),
      turnDateNewMinute: moment().format('mm'),
      hourArr: (() => {
        return getTimeArr(24)
      })(),
      minuteArr: (() => {
        return getTimeArr(60)
      })(),
      clearType: 0,
      turnRemark: '',
      screenSize: SCREENSIZE,
      screenChild: SCREENSIZE[1].children,
      screenChildValue: '2*2',
      switchArr: [
        {
          id: 3,
          value: 3,
          name: '15秒'
        },
        {
          id: 0,
          value: 0,
          name: '30秒'
        },
        {
          id: 1,
          value: 1,
          name: '1分钟'
        },
        {
          id: 2,
          value: 2,
          name: '2分钟'
        }
      ],
      switchPre: 3,
      turnDate: null,
      turnStartTime: null,
      turnEndTime: null,
      turnDateRange: null,
      turnMode: undefined,
      turnModeList: [
        {
          id: 0,
          value: 0,
          name: '随机轮巡'
        },
        {
          id: 1,
          value: 1,
          name: '收藏轮巡'
        },
        {
          id: 2,
          value: 2,
          name: '摄像机组轮巡'
        }
      ],
      turnWay: undefined,
      turnWayList: [
        {
          id: 0,
          value: 0,
          name: '组内轮巡'
        },
        {
          id: 1,
          value: 1,
          name: '组间轮巡'
        }
      ],
      turnContentList: [],
      turnContent: undefined,
      loadEditFirst: true
    }
  },
  beforeCreate() {},
  components: {
    CameralSelect
  },
  computed: {
    ...mapState({
      selectCameraList: (state) =>
        state.collect.selectCameraList,
      cameraGroupLists: (state) =>
        state.resource.cameraGroupLists,
      editTurnParams: (state) =>
        state.cameraPatrol.editTurnParams
    })
  },
  watch: {
    screenSizeValue() {
      /*  this.setScreenChild(); */
    },
    visible() {
      // this.getCameraGroup();fix耗费性能

      this.initTurnData()
    }
    /* selectAllMode(nMode) {
      //根据不同的模式监听，all,collect,来设置默认的全部摄像机数据
      if (nMode === 'all') {
        this.setCurrentComIndex(0);
      } else if (nMode === 'collect') {
        this.setCurrentComIndex(1);
      }
    }, fix这里并不能完全适应情况*/
  },
  mounted() {
    this.initTurnData()
  },
  methods: {
    ...mapMutations([
      'setSelectCameraList',
      'setCameraTab',
      'setEditTurnParams',
      'setCurrentComIndex'
    ]),
    ...mapActions([
      'addCameraPatrol',
      'getCameraGroupCondition',
      'modifyCameraPatrol'
    ]),
    getFormatDay(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    initTurnData() {
      // console.error('haha', this.editTurnParams);
      if (this.editTurnParams) {
        this.tempName = this.editTurnParams.cameraPatrolName
        this.switchPre = this.editTurnParams.cameraPatrolFrequency
        this.screenSizeValue = this.editTurnParams.cameraPatrolFrame
        //轮巡状态
        this.turnMode =
          this.editTurnParams.cameraPatrolFlag === 2
            ? 0
            : '' //fix可能不需要
        // this.screenChildValue = this.editTurnParams.cameraPatrolFrameInfo;
        // this.screenChildValue = '2*2';
        /* this.turnDateRange = [
          moment(this.editTurnParams.cameraPatrolStartTime),
          moment(this.editTurnParams.cameraPatrolEndTime),
        ]; */
        this.screenChild = this.screenSize.filter(
          (item) => item.value === this.screenSizeValue
        )[0].children
        this.screenChildValue = this.editTurnParams.cameraPatrolFrameInfo
        this.clearType = this.editTurnParams.highDefinition
        this.turnTime = this.editTurnParams.immediately
        this.turnRemark = this.editTurnParams.cameraPatrolDescribe
        let dateObj =
          this.editTurnParams.cameraPatrolStartTime &&
          moment(this.editTurnParams.cameraPatrolStartTime)
        if (dateObj) {
          this.turnDateNewHour = dateObj.format('HH')
          this.turnDateNewMinute = dateObj.format('mm')
          this.turnDateNew = dateObj
        }

        // this.setScreenChild();
      }
    },
    getCameraGroup() {
      let obj = {
        currPage: 0,
        pageSize: 0,
        patrolPlanFlag: 1
      }
      this.getCameraGroupCondition(obj)
    },
    handleTurnContentChange(val) {
      this.turnContent = val
      // console.error(val);
    },
    getCollectExtraData() {
      return {
        cameraPatrolFlag: 2, //fix以前是1，现在改为2（需求变更）

        cameraPatrolInfo: this.selectCameraList.map(
          (item) => item.cameraId
        )

        // cameraPatrolPlanStatus: 1,
      }
    },
    getGroupExtraData() {
      return {
        cameraPatrolFlag: 0,
        cameraPatrolInfo:
          this.turnWay === 0
            ? [this.turnContent]
            : this.turnContent
        // cameraPatrolPlanStatus: 1,
      }
    },
    getCameraExtraData() {
      // console.error('haha', this.selectCameraList);
      return {
        cameraPatrolFlag: 2,
        cameraPatrolInfo: this.selectCameraList.map(
          (item) => item.cameraId
        )
        // cameraPatrolPlanStatus: 1,
      }
    },
    handleOk(e) {
      this.confirmLoading = true
      /* let startTime = moment(this.turnStartTime).format('HH:mm:ss');
      let endTime = moment(this.turnEndTime).format('HH:mm:ss');
      let date = moment(this.turnDate).format('YYYY-MM-DD HH:mm:ss'); */
      /*  if (this.tempName == '') {
        this.$message.error('预案名称不能为空');
        this.confirmLoading = false;
        return;
      } fix修改默认tempName*/
      /* if(this.isEdit) {
             }else{

      } */
      if (this.tempName === '') {
        this.tempName = this.isEdit
          ? this.editTurnParams.cameraPatrolName
          : '新建轮巡预案'
      }

      /* if (
        (this.selectAllMode === 'collect' &&
          this.selectCameraList.length <= 0) ||
        (this.selectAllMode === 'all' &&
          this.turnMode === 0 &&
          this.selectCameraList.length <= 0) ||
        (this.selectAllMode === 'all' &&
          this.turnMode === 1 &&
          this.selectCameraList.length <= 0)
      ) {
        this.$message.error('请选择摄像机');
        this.confirmLoading = false;
        return;
      } fix以前*/
      if (
        (this.selectAllMode === 'collect' &&
          this.selectCameraList.length <= 0) ||
        (this.selectAllMode === 'all' &&
          this.selectCameraList.length <= 0)
      ) {
        this.$message.error('请选择摄像机')
        this.confirmLoading = false
        return
      }

      /* if (
        (this.selectAllMode === 'group' && this.turnContent == undefined) ||
        (this.selectAllMode === 'all' &&
          this.turnMode === 2 &&
          this.turnContent == undefined)
      ) {
        this.$message.error('请选择轮巡内容');
        this.confirmLoading = false;
        return;
      } fix以前*/
      if (
        this.screenSizeValue == undefined ||
        this.screenChildValue == undefined
      ) {
        this.$message.error('预案模式不能为空')
        this.confirmLoading = false
        return
      }
      /*  let cameraPatrolEndTime =
        this.turnDateRange.length > 0
          ? moment(this.turnDateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : ''; 
      let cameraPatrolStartTime =
        this.turnDateRange.length > 0
          ? moment(this.turnDateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '';fix以前*/
      let cameraPatrolStartTime =
        this.turnTime === 1
          ? moment().format('YYYY-MM-DD HH:mm:ss')
          : `${moment(this.turnDateNew).format(
              'YYYY-MM-DD'
            )} ${this.turnDateNewHour}:${
              this.turnDateNewMinute
            }:00`
      let data = {
        // cameraPatrolEndTime,
        cameraPatrolFrame: this.screenSizeValue,
        cameraPatrolFrameInfo: this.screenChildValue,
        cameraPatrolFrequency: this.switchPre,
        cameraPatrolName: this.tempName,
        cameraPatrolStartTime,
        cameraPatrolPlanStatus: 0,
        cameraPatrolDescribe: this.turnRemark.trim(),
        // highDefinition: this.clearType,
        immediately: this.turnTime
      }

      /*  if (this.selectAllMode === 'collect') {
        let extraData = this.getCollectExtraData();
        data = { ...data, ...extraData };
      } else if (this.selectAllMode === 'group') {
        let extraData = this.getGroupExtraData();
        data = { ...data, ...extraData };
      } else if (this.selectAllMode === 'all') {
        let extraData = {};
        if (this.turnMode === 0) {
          extraData = this.getCameraExtraData();
        } else if (this.turnMode === 1) {
          extraData = this.getCollectExtraData();
        } else if (this.turnMode === 2) {
          extraData = this.getGroupExtraData();
        }
        data = { ...data, ...extraData };
      } fixsxy*/
      if (this.selectAllMode === 'collect') {
        let extraData = this.getCollectExtraData()
        data = { ...data, ...extraData }
      } else if (this.selectAllMode === 'all') {
        let extraData = this.getCameraExtraData()

        data = { ...data, ...extraData }
      }
      if (this.isEdit) {
        let newData = {
          id: this.editTurnParams.id,
          params: data
        }
        this.modifyTurn(newData)
      } else {
        this.addTurn(data)
      }
      e.preventDefault()
    },
    modifyTurn(data) {
      this.modifyCameraPatrol(data)
        .then((data) => {
          if (data.code === 200) {
            this.$message.success('修改轮巡预案成功')
            this.resetData()
            this.$emit('refreshData')
          }
        })
        .catch(() => {
          this.resetData()
        })
    },
    addTurn(data) {
      let instructionsDel = null
      if (this.selectAllMode === 'collect') {
        instructionsDel = {
          module: '重点关注',
          page: '收藏列表',
          feature: '创建收藏轮巡',
          description: `创建收藏轮巡:  ${data.cameraPatrolName}`
        }
      }
      let objParams = {
        data,
        instructionsDel
      }
      this.addCameraPatrol(objParams)
        .then((data) => {
          if (data.code === 200) {
            this.$message.success('新增轮巡预案成功')
            this.resetData()
            if (this.selectAllMode === 'all') {
              this.$emit('refreshData')
            }
          }
        })
        .catch(() => {
          this.resetData()
        })
    },
    resetData() {
      this.turnEndTime = ''
      this.screenSizeValue = 4
      this.screenChildValue = '2*2'
      this.screenChild = SCREENSIZE[1].children
      this.turnMode = undefined
      this.switchPre = 3
      this.tempName = '新建轮巡'
      this.turnStartTime = null
      this.turnDate = null
      this.confirmLoading = false
      this.turnDateRange = null
      this.turnContent = undefined
      this.turnWay = undefined
      //新增云南轮巡优化
      this.turnDateNew = moment()
      this.turnDateNewHour = moment().format('HH')
      this.turnDateNewMinute = moment().format('mm')
      this.turnRemark = ''
      this.turnTime = 1
      this.clearType = 0
      this.setSelectCameraList([])
      this.setEditTurnParams(null)
      this.$emit('closeDialog')
    },
    addHandle() {
      this.cameraSelectVisible = true
      /*  console.log('haha', this.selectAllMode, this.turnMode); */
      //根据收藏，所有，判断默认调用
      /* if (this.selectAllMode === 'collect') {
        this.setCurrentComIndex(1);
        this.setCameraTab('collect');
      } else if (this.selectAllMode === 'all') {
        if (this.turnMode === 0) {
          this.setCurrentComIndex(0);
        } else if (this.turnMode === 1) {
          this.setCurrentComIndex(1);
          this.setCameraTab('collect');
        }
      }fix优化以前 */
      if (this.selectAllMode === 'collect') {
        this.setCurrentComIndex(1)
        this.setCameraTab('collect')
      } else if (this.selectAllMode === 'all') {
        this.setCurrentComIndex(0)
      }
    },
    handleCancel() {
      this.resetData()
    },
    closeCameralDialogHandle() {
      this.cameraSelectVisible = false
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    setScreenChild() {
      for (let i = 0; i < this.screenSize.length; i++) {
        if (
          this.screenSize[i].value === this.screenSizeValue
        ) {
          this.screenChild = this.screenSize[i].children
          this.screenChildValue = this.editTurnParams
            ? this.editTurnParams.cameraPatrolFrameInfo
            : undefined
          break
        }
      }
    },
    changeDate(date) {
      // console.error(date, dateString);
      // console.error(moment(date).format('YYYY-MM-DD'));
      this.turnDate = date
    },
    handleTurnModeChange(value) {
      this.turnMode = value
      if (value === 0) {
        this.setCameraTab('')
        //随机轮训时触发树状调阅，获取数据
        this.setCurrentComIndex(0)
        this.setSelectCameraList([])
      } else if (value === 1) {
        this.setCameraTab('collect')
        //随机轮训时触发收藏，获取数据
        this.setCurrentComIndex(1)
        this.setSelectCameraList([])
      } else {
        this.turnWay = undefined
      }
    },
    changeStartTime(time) {
      // console.error(time, timeString);
      this.turnStartTime = time
      // console.error(getSeconds(timeString));
    },
    changeEndTime(time) {
      // console.log(time, timeString);
      this.turnEndTime = time
      /* if (timeDiff(this.turnEndTime, this.turnStartTime) <= 0) {
        this.$message.error('截止时间必须大于开始时间！');
      } */
      if (
        moment(this.turnEndTime).unix() -
          moment(this.turnStartTime).unix() <=
        0
      ) {
        this.$message.error('截止时间必须大于开始时间！')
      }
    },
    handleTurnWayChange(value) {
      this.turnWay = value
      this.turnContent = undefined
      this.getCameraGroup()
    },
    changeDataRange(date) {
      // console.error(date, dateString);
      this.turnDateRange = date
    },
    onChangeTurnTime(e) {
      this.turnTime = e.target.value
      this.turnDateNewHour = moment().format('HH')
      this.turnDateNewMinute = moment().format('mm')
    },
    onChangeTurnDate(date) {
      this.turnDateNew = date
    },
    handleHourChange(value) {
      this.turnDateNewHour = value
    },
    handleMinuteChange(value) {
      this.turnDateNewMinute = value
    },
    onChangeClearType(e) {
      this.clearType = e.target.value
    },
    handleScreenSize(val) {
      this.screenSizeValue = val
      this.screenChild = this.screenSize.filter(
        (item) => item.value === val
      )[0].children
      this.screenChildValue = undefined
    }
  }
}
</script>

<style scoped>
.wd-name {
  width: 80px;
}
.tips {
  padding-left: 80px;
}
</style>
